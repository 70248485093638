.swipe-card {
  height: 100%;
  position: relative;
  border-radius: var(--swipe-card-border-radius);


  //IMAGES

  .custom-pagination {
    display: none !important;
  }

  .card-caption {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0;
    right: 0;
    padding: 0;
    z-index: 5;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.3) 10%, rgba(40, 50, 60, 0.6) 100%);
    color: #fff;
    border-radius: 0 0 var(--swipe-card-border-radius) var(--swipe-card-border-radius);
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(0, 0, 0, 0.05);
    pointer-events: none;
  }


  //TOP
  .card-top-data {
    margin-top: 45px;
    margin-right: 10%;
    margin-left: 10%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;

    span {
      margin-left: 3px;
      margin-right: 3px;
    }

    .status-online {
      color: #0CBC8B;
      scale: 1.5;
      margin-top: -3px;
      margin-right: 8px;
    }

    .status-offline {
      color: #C2C0C0;
      scale: 1.5;
      margin-top: -3px;
      margin-right: 8px;
    }

  }

  //BOTTOM
  .card-bottom-data {
    width: 70%;
    margin-right: 4%;
    margin-left: 4%;
    position: absolute;
    justify-content: center;
    align-items: center;
    bottom: 90px;
  }

  .card-user-info {
    line-height: 1.4em;
    font-size: 12px;
    font-weight: 600;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;

    ion-icon {
      margin-right: 8px;
      font-size: 12px;
    }
  }

  .passion-list {
    ion-button {
      --padding-start: 12px;
      --padding-end: 12px;
      --border-width: 0;
      margin-right: 8px;
      margin-bottom: 8px;
      border-radius: 999em;
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      background-color: rgba(255, 255, 255, 0.5);
    }
  }


  //ACTION BUTTONS
  .card-action-buttons {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 20%;
    margin-right: 4%;
    right: 0;
    bottom: 80px;
    align-items: end;
    gap: 15px;
    pointer-events: all;
    z-index: 100;

    ion-icon {
      color: white;
    }



    .circular-button {
      display: flex;
      width: 40px;
      height: 40px;
      align-items: center;
      justify-content: center;
      border-radius: 20px;

      ion-icon.button-normal {
        width: 28px;
        height: 28px;
        color: white;
      }

    }


  }




}