ion-title {
        color: #333333;
        font-weight: 600 !important;
}

.star-icon {
        color: #FFB458;
}

.reviews-title {
        color: #6C6C6C;
        text-align: center;
        width: 100%;
        display: block;
        font-weight: 600;
        font-size: small;
}

.reviews-container {
        margin-left: 20px;
        margin-right: 20px;
}