.profile-image-slides {
  height: 100%;
  
  .slides {
    height: 100%;

    .slide-img {
      width: 100%;
      height: 100%;
      border-radius: 0px !important;
    }
  }

  .custom-pagination {
    position: absolute;
    left: 0;
    top: 8px;
    width: 100%;
    height: 4px;
    z-index: 10;
    display: flex;
    justify-content: space-around;
    padding: 0 8px;

    .pagination-bullet {
      width: 100%;
      height: 3px;
      display: block;
      background-color: rgba(0, 0, 0, .1);
      border-radius: 999em;
      margin: 0 2px;
      &.pagination-bullet-active {
        background-color: #fff;
      }
    }
  }

  .overlay-navigation {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .navi {
      height: 100%;
      width: 50%;
    }
  }

}
