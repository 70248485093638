.services-page {

  //HEADER
  ion-header {
    display: block;

    ion-toolbar {
      height: 64px;
      --background: white;

      ion-buttons {
        margin: auto;
        cursor: pointer;
        height: 35px;

        .circular-button {
          display: flex;
          justify-content: center;
          align-items: center;
          background: #F7F7F7;
          border-radius: 20px;
          width: 30px;
          height: 30px;
        }

        ion-icon {
          font-size: larger;
          color: #D3D3D3;
        }

        ion-avatar {
          width: 35px;
          height: 35px;
        }

      }

      .search-bar {
        height: 52px;
        width: 95%;
        margin: auto;
        margin-left: 10px;
        padding: 0;

        ion-icon {
          display: none;
        }

        input {
          border-radius: 20px;
          background: #F7F7F7;
          color: #848484;
          text-align: center;
          font-weight: 600;
          font-size: smaller;
        }
      }

    }

  }

  //SEGMENT
  ion-segment {
    height: 44px;
  }

  ion-segment ion-segment-button {
    font-size: small;
    font-weight: 700;
  }


  //CONTENT
  ion-content {
    --background: #F7F7F7;
  }

  .safe-area-bottom {
    //--offset-top: -64px !important;
  }

  .segment-view {
    padding-bottom: 100px;
    background: #F7F7F7;
  }

  .boost-promotion {
    margin: 12px 24px 8px;
    padding: 16px;
    background: linear-gradient(var(--ion-color-white) 0%, rgba(var(--ion-color-boost-rgb), 0.3) 100%);
    text-align: center;
    border-radius: 0 0 36px 36px;
  }

  .offer-message {
    text-align: center;
    padding: 24px;
  }

  .card-container {
    width: 100% !important;
    background: #F7F7F7;
    padding-top: 8px;
    padding-bottom: 4px;
  }


  //BUTTON
  .add-button {
    margin-bottom: 80px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }


}