.report-page {


    //TOP
    .top-emoji {
        display: flex;
        width: 60px;
        height: 60px;
        margin: auto;
        margin-top: 0px;
        font-size: 32px;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.01);
        background: white !important;
    }

    .top-title {
        color: var(--c-dark-gray);
        margin-top: 15px !important;
        text-align: center;
        margin: auto;
        font-size: 32px;
        font-weight: 500;
    }

    .top-description {
        width: 100%;
        margin: auto;
        margin-left: 20px;
        margin-right: 20px;
        text-align: center;
        font-size: 12px;
        line-height: 22px;
        color: var(--c-light-gray);
    }

    //LIST 
    ion-list-header {
        padding-left: 0px;
        margin-top: 30px;

        ion-label {
            font-size: smaller;
        }
    }

    .list-custom {

        margin-left: 20px;
        margin-right: 20px;
        background: transparent;

        ion-item {
            margin-top: 12px;
            background: transparent;
            --background: white;
            --border-radius: 10px;
            --border-width: 1px;
            --border-style: solid;
            --border-color: #F0F0F0;
            cursor: pointer;

            &.active {
                --border-color: #FC566D;
            }
        }

        ion-icon {
            color: #92949C;
            font-size: large;
        }

        ion-note {
            font-size: smaller;
            font-weight: 600;
            color: #92949C;
        }

        textarea {
            border-radius: 10px;
            background: #F7F7F7;
            margin-top: 8px;
            margin-bottom: 8px;
            padding-left: 8px;
            padding-right: 8px;
            color: #92949C;
            font-size: smaller;
            font-weight: 600;
        }

        .counter-text {
            color: #FC566D;
            font-size: 8px;
            margin-top: 15px;
        }

    }

    .bottom-actions {
        margin-top: 30px;
        display: flex;
        flex-direction: column;

        .continue-button {
            width: 80%;
            height: 48px;
            margin: auto;
            background: #FD5068;
            display: flex;
            border-radius: 20px;

            span {
                width: 100%;
                color: white;
                text-align: center;
                margin: auto;
                font-size: small;
                font-weight: 600;
            }

        }

        .skip-text {
            color: #FC566D;
            font-weight: 500;
            font-size: small;
            margin: 20px auto;
        }
    }


}