//HEADER
.discover-page {

  .no-results-page {
    background: #F7F7F7;

    .no-results-info {
      position: absolute;
      bottom: 25%;
      text-align: center;
      max-width: 70%;

      .img {
        width: 90%;
        object-fit: contain;
        max-height: 300px;
        margin-bottom: 20px;
      }

      .text-title {
        font-weight: 600;
        font-size: 14px;
        color: #333333;
      }

      .text-subtitle {
        font-weight: 600;
        font-size: 12px;
        color: #92949C;
      }

    }

  }



  .discover-toolbar {
    --background: ion-color-white;
    height: 110px;

    ion-avatar {
      height: 2.5rem;
      margin-left: 10px;
      width: 2.5rem;
    }

    ion-title {
      img {
        height: 20px;
      }
    }

    ion-button {
      min-height: 40px;
    }

    .filter-button {
      display: flex;
      width: 40px;
      height: 40px;
      align-items: center;
      justify-content: center;
      border-radius: 20px;

      ion-icon {
        width: 28px;
        height: 28px;
        color: white;
      }

    }

  }

  .header-md::after {
    background-image: none !important;
  }

  //CONTENT
  ion-content {
    position: absolute;
  }


  &.discover-bg {
    --background: linear-gradient(var(--ion-color-white), var(--ion-color-light));
  }

  .segment-fit {
    width: 88px;
    border-radius: 999em;

    ion-segment-button {
      min-width: 20px;
      --padding-start: 12px;
      --padding-end: 12px;
      --border-radius: 999em;

      &.segment-button-checked {
        ion-icon {
          color: var(--ion-color-gold);
        }
      }
    }

    ion-icon {
      font-size: 22px;
    }
  }

  .cards-container {
    height: 100%;
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: black;
  }

  .card-stack {
    height: 100%;
    position: relative;
    z-index: 0;

    &.rotate-right {
      animation: rotateRight 250ms ease-in-out;
    }

    &.rotate-left {
      animation: rotateLeft 250ms ease-in-out;
    }

    .card-item {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      //border-radius: var(--swipe-card-border-radius);
      overflow: hidden;
      box-shadow: var(--card-box-shadow);
      will-change: transform;
      background-color: var(--ion-color-light);

      .stamp {
        position: absolute;
        top: 20px;
        right: 10px;
        z-index: 300;
        font-size: 30px;
        color: var(--ion-color-primary);
        border: 4px solid var(--ion-color-primary);
        border-radius: 4px;
        padding: 0px 4px;
        letter-spacing: 1px;
        font-weight: 700;
        text-transform: uppercase;
        transform: rotate(15deg);
        opacity: 0;
        box-shadow: 0 2px 6px 0 rgba(112, 125, 134, 0.14);
        text-shadow: 0 2px 6px rgba(112, 125, 134, 0.14);
        will-change: opacity;

        &.stamp-like {
          right: initial;
          left: 10px;
          color: var(--ion-color-secondary);
          border-color: var(--ion-color-secondary);
          transform: rotate(-15deg);
          letter-spacing: 3px;
        }
      }
    }
  }

  .card-actions {
    bottom: 0px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 100%;

    ion-button {
      &.button-revert ion-icon {
        color: var(--color-button-revert);
      }

      &.button-dislike {
        border-color: var(--color-button-dislike);
      }

      &.button-dislike ion-icon {
        color: var(--color-button-dislike);
      }

      &.button-star {
        color: var(--color-button-star);
      }

      &.button-like {
        color: var(--color-button-like);
      }

      &.button-boost ion-icon {
        color: var(--color-button-boost);
      }
    }
  }

  .card-info {
    margin: 0px 20px 0px 20px;
    font-size: medium;

    a {
      font-weight: bold;
      cursor: pointer;
    }
  }

  @media (max-width: 386px) {
    .button-custom {
      &.button-icon {
        width: 48px;
        height: 48px;

        &.button-lg {
          width: 56px;
          height: 56px;

          ion-icon {
            font-size: 32px;
          }
        }
      }
    }
  }

  @keyframes rotateRight {
    from {
      transform: perspective(1000px) rotateY(4deg)
    }

    to {
      transform: perspective(1000px) rotateY(0deg)
    }
  }

  @keyframes rotateLeft {
    from {
      transform: perspective(1000px) rotateY(-4deg)
    }

    to {
      transform: perspective(1000px) rotateY(0deg)
    }
  }

}


//FILTER MODAL
.filter-content {
  background: #F7F7F7;
  width: 100%;
  height: 100%;

  //SEARCH
  .search-container {
    position: relative;
    margin-left: 20px;
    margin-right: 20px;
    cursor: pointer;


    .search-bar {
      height: 52px;
      width: 100%;
      margin: auto;
      padding: 0;

      ion-icon {
        color: #999BA3;
        padding-left: 8px;
        --ionicon-stroke-width: 60px;
      }

      input {
        border-radius: 10px;
        background: white;
        text-align: start;
        font-size: smaller;
        font-weight: bold;
        padding-left: 52px !important;
        box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1);
      }
    }

    .search-red-icon {
      background: #FC566D;
      position: absolute;
      right: 12px;
      top: 18px;
      padding: 2px;
      border-radius: 20px;
      font-size: x-small;
    }

  }


  .filter-button {
    position: fixed;
    bottom: 30px;
    width: 100%;
    display: flex;
    flex-flow: column;
    left: 0;
    right: 0;


    div {
      display: flex;
    }

    p {
      width: 100%;
      margin-left: 20px;
      margin-right: 20px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

  }


  //INTERESTS
  //LIST 
  ion-list-header {
    padding-left: 0px;
    margin-top: 30px;

    ion-label {
      font-size: smaller;
    }


  }


  .list-custom {

    margin-left: 20px;
    margin-right: 20px;
    background: transparent;

    ion-item {
      margin-top: 12px;
      background: transparent;
      --background: white;
      --border-radius: 10px;
      --border-width: 1px;
      --border-style: solid;
      --border-color: #F0F0F0;
      cursor: pointer;
    }

    ion-icon {
      color: #92949C;
      font-size: large;
    }

    ion-note {
      font-size: smaller;
      font-weight: 600;
    }

    .small-note {
      font-size: 12px;
    }

    .editable {
      color: #333333;
    }

    .red-circular-icon {
      background: #FC566D;
      color: white;
      border-radius: 20px;
      font-size: smaller;
      padding: 1px;
      margin-right: 12px;
    }

    .red-icon {
      color: #FC566D;
    }

    textarea {
      color: #333333;
      font-size: smaller;
      font-weight: 600;
    }

    .note {
      color: #92949C;
      font-size: small;
      font-weight: normal;
    }


    .badges {
      display: flex;
      flex-direction: row;
      padding: 8px 2px;
      flex-wrap: wrap;
    }

    .badge {
      width: fit-content;
      --background: white;
      --border-color: #EDEDEE;
      --border-style: solid;
      --border-width: 1px;
      --color: #333333;
      --border-radius: 20px;
      font-size: small;
      font-weight: 600;
    }

    .variable-height {
      ion-icon:first-child {
        position: absolute;
        margin-top: 15px;
        top: 0;
      }

      ion-icon {
        margin-top: auto;
        margin-bottom: auto;
      }

      ion-textarea {
        margin-left: 40px;
      }

      .badges {
        margin-left: 40px;
      }
    }

  }



  .multi-slider {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-left: 1px;
    margin-right: 1px;

    .slider {
      width: 100%;
    }

    .slider__left-value {
      display: none;
    }

    .slider__right-value {
      display: none;
    }

    .slider__range {
      background: #333;
    }

    .thumb {
      //scale: 0.6;
      //top: 24px;
      width: 100%;
      margin-top: 2px;
    }

    .thumb::-webkit-slider-thumb,
    .thumb::-moz-range-thumb {
      -webkit-appearance: none;
      width: 16px;
      height: 16px;
      background-color: #FC566D;
      box-shadow: 0px 0px 0px #000000;
      overflow: visible;
      cursor: pointer;
    }

  }


}