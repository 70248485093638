.profile-edit-page {
  .photos-edit {
    padding: 4px;

    .photo-item {
      position: relative;

      &.no-photo .photo-image {
        border: 2px dashed rgba(var(--ion-color-medium-rgb), .7);
      }
    }

    .photo-image {
      background-color: var(--ion-color-light-shade);
      border-radius: 6px;
      height: 0;
      padding-top: 150%;
    }

    .photo-button-frame {
      display: flex;
      flex-flow: row-reverse;
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 28px;
      text-align: right;

      ion-icon {
        color: var(--ion-color-primary);
      }

      &.photo-button-invert {
        background: linear-gradient(to top right, var(--color-courtli-stop1) 0%, var(--color-courtli-stop2) 100%) !important;

        ion-icon {
          color: var(--ion-color-white);
        }
      }
    }

    .photo-button {

      background-color: var(--ion-color-light);
      box-shadow: 0 2px 6px 0 rgba(112, 125, 134, 0.14);
      border-radius: 999em;
      width: 28px;
      height: 28px;
      font-size: 18px;
      text-align: center;
      padding-top: 5px;

      ion-icon {
        color: var(--ion-color-primary);
      }

      &.photo-button-invert {
        background: linear-gradient(to top right, var(--color-courtli-stop1) 0%, var(--color-courtli-stop2) 100%) !important;

        ion-icon {
          color: var(--ion-color-white);
        }
      }
    }
  }




  //HEADER
  .me-header {
    text-align: center;
    margin-bottom: 16px;

    .progress-holder {
      position: absolute;
      left: 0;
      width: 100px;
      margin: 0 auto;
      right: 0;
      height: 200px;
    }

    .avatar {
      width: 100px;
      height: 100px;
      margin-top: 10px;
      display: inline-block;
    }

    ion-badge {
      border-radius: 30px;
      bottom: -12px;
      font-size: 14px;
      height: 30px;
      left: 0;
      position: absolute;
      right: 0;
      margin: 0 auto;
      width: 85%;
    }

    .detail-holder {
      margin-top: 10px;
    }

    .me-title {
      font-size: larger;
      font-weight: 700;
    }


    .me-info {
      font-size: smaller;
      font-weight: 700;
    }


  }




  //LIST 
  ion-list-header {
    padding-left: 0px;
    margin-top: 30px;

    ion-label {
      font-size: smaller;
    }
  }


  .list-custom {

    margin-left: 20px;
    margin-right: 20px;
    background: transparent;

    ion-item {
      margin-top: 12px;
      background: transparent;
      --background: white;
      --border-radius: 10px;
      --border-width: 1px;
      --border-style: solid;
      --border-color: #F0F0F0;
      cursor: pointer;
    }

    ion-icon {
      color: #92949C;
      font-size: large;
    }

    ion-note {
      font-size: smaller;
      font-weight: 600;
    }

    .editable {
      color: #333333;
    }

    .red-circular-icon {
      background: #FC566D;
      color: white;
      border-radius: 20px;
      font-size: smaller;
      padding: 1px;
      margin-right: 12px;
    }

    .red-icon {
      color: #FC566D;
    }

    textarea {
      color: #333333;
      font-size: smaller;
      font-weight: 600;
    }

    .note {
      color: #92949C;
      font-size: small;
      font-weight: normal;
    }


    .badges {
      display: flex;
      flex-direction: row;
      padding: 8px 2px;
      flex-wrap: wrap;
    }

    .badge {
      width: fit-content;
      --background: white;
      --border-color: #EDEDEE;
      --border-style: solid;
      --border-width: 1px;
      --color: #333333;
      --border-radius: 20px;
      font-size: small;
      font-weight: 600;
    }

    .variable-height {
      ion-icon:first-child {
        position: absolute;
        margin-top: 15px;
        top: 0;
      }

      ion-icon{
        margin-top: auto;
        margin-bottom: auto;
      }

      ion-textarea{
        margin-left: 40px;
      }

      .badges{
        margin-left: 40px;
      }
    }

  }


  .list-custom.last-child {
    margin-bottom: 200px;
  }

  .preview-container {
    padding: 8px 8px 36px;
  }

  .text-sm {
    font-size: var(--font-size-sm);
  }

  .card-border {
    border-radius: var(--swipe-card-border-radius);
    overflow: hidden;

    &.rotate-right {
      animation: rotateRight 250ms ease-in-out;
    }

    &.rotate-left {
      animation: rotateLeft 250ms ease-in-out;
    }
  }

  @keyframes rotateRight {
    from {
      transform: perspective(1000px) rotateY(4deg)
    }

    to {
      transform: perspective(1000px) rotateY(0deg)
    }
  }

  @keyframes rotateLeft {
    from {
      transform: perspective(1000px) rotateY(-4deg)
    }

    to {
      transform: perspective(1000px) rotateY(0deg)
    }
  }
}