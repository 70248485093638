.card-image-slides {
  height: 100%;

  .slides {
    height: 100%;
    pointer-events: none;

    .slide-img {
      width: 100%;
      height: 100%;
      border-radius: 0px !important;
    }
  }

  .custom-pagination {
    position: absolute;
    left: 0;
    top: 8px;
    width: 100%;
    height: 4px;
    z-index: 10;
    display: flex;
    justify-content: space-around;
    padding: 0 8px;

    .pagination-bullet {
      width: 100%;
      height: 3px;
      display: block;
      background-color: rgba(0, 0, 0, .1);
      border-radius: 999em;
      margin: 0 2px;

      &.pagination-bullet-active {
        background-color: #fff;
      }
    }
  }

  .overlay-navigation {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .navi {
      height: 100%;
      width: 50%;
    }

  }

  .image-bullet-container {
    position: absolute;
    display: flex;
    height: 100%;
    width: 10px;
    top: 0;
    left: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    z-index: 6;


    .nav-icon-container.up {
      width: 50px;
      height: 60px;
      margin-bottom: -35px;
    }

    .nav-icon-container.down {
      width: 50px;
      height: 60px;
      margin-top: -35px;
    }

    .nav-icon {
      color: white;
    }

    .nav-icon.up {
      margin-bottom: 5px;
    }

    .nav-icon.down {
      margin-top: 5px;
    }

    .bullet {
      width: 10px;
      height: 10px;
      border-radius: 20px;
      border-color: white;
      border-style: solid;
      border-width: 1px;
    }

  }

}