.activity-page {

    background: #f7f7f7 !important;

    .search-container {
        position: relative;
        margin-left: 20px;
        margin-right: 20px;
        cursor: pointer;


        .search-bar {
            height: 52px;
            width: 100%;
            margin: auto;
            padding: 0;

            ion-icon {
                color: #999BA3;
                padding-left: 8px;
                --ionicon-stroke-width: 60px;
            }

            input {
                border-radius: 10px;
                background: white;
                text-align: start;
                font-size: smaller;
                font-weight: bold;
                padding-left: 52px !important;
                box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1);
            }
        }

        .search-red-icon {
            background: #FC566D;
            position: absolute;
            right: 12px;
            top: 18px;
            padding: 2px;
            border-radius: 20px;
            font-size: x-small;
        }

    }


    .filrers-container {
        display: flex;
        flex-direction: row;
        position: relative;
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 16px;
        justify-content: center;
        gap: 4%;
        cursor: pointer;

        .matches-filter {
            position: relative;
            width: 48%;
            background: white;
            border-radius: 8px;
            box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1);
            padding: 4px 8px;
            display: flex;
            align-items: center;

            span {
                color: #999BA3;
                font-weight: 600;
                font-size: small;
            }

            ion-icon {
                position: absolute;
                color: #b1b3bd;
                padding-left: 4px;
                --ionicon-stroke-width: 60px;
                right: 5px;
            }

        }


    }


    //CONTENT

    ion-content {

        --background: transparent;

        //LIST 
        ion-list-header {
            padding-left: 0px;
            margin-top: 30px;

            ion-label {
                font-size: smaller;
            }
        }

        .list-custom {

            margin-left: 15px;
            margin-right: 15px;
            background: transparent;


            .item {

                margin-top: 12px;
                --padding-top: 10px;
                --padding-bottom: 10px;

                ion-avatar {
                    margin-right: 15px;
                }

                .item-date {
                    display: block;
                    margin-left: auto;
                    position: relative;
                    width: fit-content;
                    margin-right: 10px;
                    margin-bottom: 2px;
                    color: #92949C;
                    font-weight: 700;
                    font-size: x-small;
                }

                ion-item {
                    margin-top: 0;
                    background: transparent;
                    --background: white;
                    --border-radius: 10px;
                    --border-width: 1px;
                    --border-style: solid;
                    --border-color: #F0F0F0;
                    cursor: pointer;
                }

                ion-icon {
                    color: #92949C;
                    font-size: large;
                }

                ion-note {
                    font-size: smaller;
                    font-weight: 600;
                    color: black;

                    span {
                        color: #92949C;
                        font-weight: 700;
                        font-size: x-small;
                    }

                }

            }

        }

    }
}