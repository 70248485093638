.service-edit-page {

  //HEADER
  ion-header {
    display: block;

    ion-toolbar {
      height: 64px;
      --background: white;

      ion-buttons {
        margin: auto;
        cursor: pointer;
        height: 35px;
      }

    }

  }

  //CONTENT
  .safe-area-bottom {
    margin-bottom: 80px;
    margin-left: 4%;
    margin-right: 4%;
  }

  .service-image {
    margin: auto;
    border-radius: 20px;
    display: block;
  }

  .service-title {
    margin-top: 15px;
    background: #F9F9F9;
    border-radius: 5px;
    border-style: dashed;
    border-width: 1px;
    border-color: #C3C3C3;
    padding: 10px;
    font-weight: 700;
    color: #6C6C6C;
    font-size: medium;
  }

  .service-cost {
    margin-top: 10px;
    background: #F9F9F9;
    border-radius: 5px;
    border-style: dashed;
    border-width: 1px;
    border-color: #C3C3C3;
    padding: 10px;
    font-weight: 700;
    color: #6C6C6C;
    font-size: medium;
  }

  .section-title {
    color: #333333;
    margin-top: 20px;
    margin-bottom: 0px;
    font-weight: 700;
    margin-left: 10px;
    font-size: small;
  }

  .section-description {
    margin-top: 5px;
    background: #F9F9F9;
    border-radius: 5px;
    border-style: dashed;
    border-width: 1px;
    border-color: #C3C3C3;
    padding: 10px;
    font-size: small;
    color: #6C6C6C;
    font-weight: 500;
  }

  .star-icon {
    color: #FFB458;
  }



  //MENTOR
  .mentor-container {
    align-items: center;
    padding: 5px 10px 5px 10px;

    .ctiny_avatar {
      width: 35px;
      height: 35px;
    }

    .mentor-info {
      margin-left: 8px;
      position: relative;
    }

    .mentor-name {
      font-weight: 600;
      font-size: small;
      color: #333333;
      align-items: center;
      display: flex;
      gap: 5px;

      ion-icon {
        color: #367CFF;
        font-size: large;
      }
    }

    .mentor-level {
      font-weight: 500;
      font-size: x-small;
      color: #848484;
      text-align: left;
    }

  }


  .mentor-description {
    font-weight: 500;
    font-size: x-small;
    color: #9C9C9C;
    margin-left: 10px;
    margin-right: 10px;
  }

  .similar-services {
    display: flex;
    align-items: baseline;

    div {
      min-width: fit-content;
    }

    .empty-space {
      width: 100%;
    }

  }

  .red-button {
    font-size: small;
    color: #FC566D;
    font-weight: 600;
    cursor: pointer;
    align-items: center;
    display: flex;
    gap: 5px;
  }


  .reviews-button {
    display: flex;
    align-items: center;
    font-size: small;
    float: right;
    margin-top: 10px;
    gap: 7px;

    span {
      display: flex;
      align-items: center;
    }

  }

}

.edit_image_button {
  padding: 2px 5px;
  border-radius: 15px;
  color: white;
  position: absolute;
  background-color: RGBA(0, 0, 0, 0.5);
  top: 10px;
  right: 20px;
}