.profile-page {

  --background: transparent;
  background: transparent;
  backdrop-filter: blur(15px);
  overflow: hidden;


  .profile-container {
    top: 80px;
    position: relative;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background: white;
  }

  .random-avatar {
    position: relative;
    margin-bottom: 25px;
    top: -56px;
  }

  .avatar {
    left: 0;
    right: 0;
    border-width: 4px;
    border-style: solid;
    border-color: white;
  }

  .user-content {
    position: fixed;
    left: auto;
    right: auto;
    background: white;
    width: 100%;
    border-radius: 20px 20px 0px 0px;
  }

  .user-content div {
    margin-bottom: 6px;
    font-size: 12px;
    color: black;
    text-align: center;
    font-weight: 700;
  }

  .name-label {
    font-size: 18px !important;
  }

  .distance-label {
    color: #C2C0C0;
  }

  .status-online {
    color: #0CBC8B;
    font-size: 20px;
  }

  .status-offline {
    color: #C2C0C0;
    font-size: 20px;
  }

  .action-buttons {
    display: flex;
    margin-top: -144px;
    margin-bottom: 20px !important;
    padding: 0 10px;
    flex-direction: row;
    gap: 5px;
    justify-content: center;
    align-items: center;



    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .small-button {
      width: 48px;
      height: 48px;

      ion-icon {
        width: 18px;
        height: 18px;
        padding: 6px;
      }
    }

    .big-button {
      width: 64px;
      height: 64px;

      ion-icon {
        width: 35px;
        height: 35px;
        padding: 8px;
      }

    }

    .space {
      width: 100px;
    }

    .circular-button {
      width: 20px;
      height: 20px;
      border-radius: 100px;
      border-width: 2px;
      border-color: white;
      border-style: solid;
      padding: 5px;
      color: white;
    }

    .blue {
      background: #367CFF;
    }

    .green {
      background: #0CBC8B;
    }

    .gray {
      background: #C2C0C0;
    }

    .red {
      background: #FD5068;
    }

  }


  .user-meta {
    width: 100%;
    height: auto;
    background: #F7F7F7;
    position: relative;
    overflow-y: scroll !important;
    -webkit-overflow-scrolling: touch !important;

    display: flex;
    flex-direction: column;
    flex: 1 1;

    .user-meta-content {

      display: flex;
      flex-direction: column;
      flex: 1 1;
      min-height: min-content;
      padding-top: 8px;
      padding-bottom: 200px;

      .card {
        display: flex;
        flex-direction: column;
        margin: 2px 10px;
        padding: 10px 15px;
        background: white;
        border-radius: 10px;
        border-width: 1px;
        border-color: #F0F0F0;
        border-style: solid;
        font-size: 12px;
        text-align: left;

        .card-title {
          color: #92949C;
          font-weight: 600;
        }

        .card-content {
          color: #333333;
          font-weight: 600;
          margin-top: 5px;
        }


        .badges {
          display: flex;
          flex-direction: row;
          margin-top: 5px;
          margin-bottom: 0px;
          flex-wrap: wrap;
          gap: 5px;

          .badge {
            width: fit-content;
            padding: 2px 12px;
            background: white;
            border-color: #EDEDEE;
            border-style: solid;
            border-width: 1px;
            color: #333333;
            border-radius: 20px;
            font-size: 12px;
            font-weight: 600;
          }

        }

        .images {
          display: flex;
          flex-direction: row;
          margin-top: 5px;
          flex-wrap: wrap;
          gap: 5px;

          .image {
            border-radius: 10px;
            width: 30%;
            height: 130px;
            object-fit: cover;
          }

        }

      }

    }

  }


  .share-button {
    position: relative;
    top: 10px;
    bottom: 30px;
    width: 100%;
    display: flex;
    flex-flow: column;
    left: 0;
    right: 0;

    div {
      display: flex;
    }

    p {
      width: 100%;
      margin-left: 10px;
      margin-right: 10px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

  }


}