@import './chatui.scss';

.chat-page {

  //HEADER
  ion-header {
    display: block;

    ion-toolbar {
      height: 64px;
      --background: white;

      ion-buttons {
        margin: auto;
        cursor: pointer;
        height: 35px;
      }

      .avatar-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;

        .user-name {
          font-weight: 600;
          font-size: large;
          color: #333333;
        }

      }


    }

  }

  //CONTENT
  .safe-area-bottom {
    //--offset-top: 0px !important;
    --offset-bottom: 0px !important;
  }

  .chat-top {
    background: #F7F7F7;

    .chat-title {
      text-align: center;
      width: fit-content;
      margin: auto;
      padding-top: 20px;

      ion-text {
        padding: 2px 20px 2px 20px;
        font-weight: 500;
        color: #92949C;
        font-size: small;
        background: white;
        border-style: solid;
        border-width: 1px;
        border-color: #F0F0F0;
        border-radius: 20px;
      }

    }

  }

  .background-content {
    background: green;
  }


  .chat-list {
    /// HEIGHT 100% ADDED SO THERE IS NO WHITE AREA IN A NEW CHAT
    height: 100%;
    background: #F7F7F7;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;


    .chat-timestamp {
      text-align: center;
      width: fit-content;
      margin: auto;
      padding-top: 30px;
      padding-bottom: 30px;

      ion-text {
        padding: 2px 20px 2px 20px;
        font-weight: 500;
        color: #92949C;
        font-size: small;
        background: white;
        border-style: solid;
        border-width: 1px;
        border-color: #F0F0F0;
        border-radius: 20px;
      }
    }

  }

  .mentor-message-background {
    background-color: #333333;
    color: white;
  }

  .mentee-message-background {
    background-color: var(--c-red);
  }

  .chat-text {
    font-weight: 500;
  }

  .match-info {
    font-size: var(--font-size-sm);
  }

  .chat_body_with_order_card {
    width: 100%;
    max-width: none !important;
  }

  ion-footer ion-toolbar {
    height: auto;

    .chat-input {
      text-area {
        background: white;
        border-radius: 20px;
        border-width: 1000px;
        color: #9C9C9C;
        font-weight: 500;
      }
    }

    .button-inner {
      color: #FD5068 !important;
    }

  }


  .select-options {
    position: absolute;
    opacity: 0;

    .select-text {
      display: none;
    }
  }

  .input-with-giphy {
    button {
      color: #FD5068;
    }

    ion-button {
      --color: red;
    }

    .ion-color-primary{
      color: #FD5068;
    }
  }

}

.custom-alert {

  .alert-wrapper {
    position: absolute;
    bottom: 20px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .alert-title {
    font-size: small;
    font-weight: 600;
  }

  .alert-message {
    display: none;
  }

  .alert-button-group {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    button {
      width: 30%;
      min-width: 30%;
      flex: auto;
      margin: 5px;
    }
  }

  .alert-button-role-cancel {
    background: black;
    color: white;
    font-size: small;
    font-weight: 600;
    border-radius: 10px;
  }

  .alert-button-role-confirm {
    background: #FD5068;
    color: white;
    font-size: small;
    font-weight: 600;
    border-radius: 10px;
  }
}




ion-select-popover {


  ion-item {
    --border-style: none;
  }

  ion-label {
    font-size: small !important;
    font-weight: 600;
  }

  label {
    font-size: small;
  }

  ion-radio {
    opacity: 0;
    width: 0px;
  }


  ion-item:nth-child(2) {
    color: #FD5068;
  }

}