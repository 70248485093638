.profile-header{
  background: white;
}

.user-profile-toolbar {
  --background: ion-color-white;

  ion-title {
    font-weight: 500;
    padding-inline: 0px;
  }
}
.temporary_options_centered{
  text-align: center;
  margin-top: 40px;
}

.me-page {
  --distance: 80px;
  --background: var(--ion-color-light);

  .vertical-layout {

    .section-upper {
      flex: 0 0 auto;
      position: relative;
      height: 100%;
      z-index: 1;
      background-color: var(--ion-color-white);
      padding-right: 10px;
      padding-left: 10px;
    }

    .section-lower {
      flex: 1 0 auto;
      position: relative;
      padding-top: calc(var(--distance) / 2);
      padding-bottom: 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .me-header {
    text-align: center;
    margin-bottom: 16px;

    .progress-holder {
      position: absolute;
      left: 0;
      width: 150px;
      margin: 0 auto;
      right: 0;
      height: 200px;
    }

    .avatar {
      width: 130px;
      height: 130px;
      margin-top: 10px;
      display: inline-block;
    }

    ion-badge {
      border-radius: 30px;
      bottom: -12px;
      font-size: 14px;
      height: 30px;
      left: 0;
      position: absolute;
      right: 0;
      margin: 0 auto;
      width: 85%;
    }

    .detail-holder {
      margin-top: 10px;
    }

    .me-title {
      font-size: larger;
      font-weight: 700;
    }


    .me-info {
      font-size: smaller;
      font-weight: 700;
    }


  }

  .me-button-group {
    gap: 15px;
  }

  .add-icon {
    background-color: #fff;
    box-shadow: 0 2px 6px 0 rgba(112, 125, 134, 0.14);
    border-radius: 999em;
    width: 24px;
    height: 24px;
    font-size: 20px;
    text-align: center;
    padding-top: 2px;

    ion-icon {
      color: var(--ion-color-primary);
    }
  }

  .button-stack {
    position: relative;
    display: inline-block;

    .button-addon {
      position: absolute;
      right: 0;
      bottom: 0;
    }

    &.primary {
      margin-top: 20px;
    }
  }

  .button-label {
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    color: var(--ion-color-medium);
    margin-top: 4px;
  }

  .button-courtli-plus {
    --padding-start: 60px;
    --padding-end: 60px;
  }

  .has-oval:before {
    content: '';
    display: block;
    height: var(--distance);
    background-color: var(--ion-color-white);
    border-radius: 0 0 50% 50%;
    box-shadow: 0 4px 9px 0 rgba(213, 218, 224, .3);
    position: absolute;
    top: calc(-1 * var(--distance) / 2);
    left: -50px;
    right: -50px;
  }

  .slide-intro {
    padding: 16px 16px 8px;
  }


  //OPTIONS
  .section-title {
    margin-left: 5%;
    margin-right: 5%;
    font-weight: bold;
    font-size: x-small;
    color: var(--c-light-gray);
  }

  ion-button {
    width: 50px !important;
    height: 50px !important;
  }

  ion-button ion-icon {
    font-size: large;
  }


  //SWITCH ROLES
  .switch-roles-container {
    left: auto;
    right: auto;
    bottom: 90px;
    position: absolute;
    width: 100%;
  }

  //DESCRIPTION
  .change-role-descripion {
    font-size: 14px;
    text-align: center;
    font-weight: 600;
    color: var(--c-light-gray);
  }


  //SLIDE BUTTON
  .slide-but {
    width: 80%;
    max-width: 300px;
    height: 50px !important;
    margin: auto;
    border-radius: 40px;
    background-color: var(--c-red) !important;
    color: white;
    font-weight: bolder;
    margin-top: 5px;
    border: none;
  }

  .slide-but ion-icon {
    color: var(--c-dark-gray);
  }

  .slide-overlay {
    margin-left: 5px;
    margin-right: 5px;
    max-width: 96%;
  }

  .slide-overlay-wrapper {
    background-color: var(--c-red) !important;
  }

  .slide-caret-wrapper {
    border-radius: 20px !important;
    background: white !important;
    width: 40px !important;
    height: 40px !important;
  }

  //BOTTOM TAB BAR
  ion-tab-bar .tab-icon-inactive {
    filter: brightness(0) invert(0) !important;
    opacity: 0.4 !important;
  }


  //MENTOR
  .mentor .slide-but {
    background-color: var(--c-mentor-green) !important;
  }

  .mentor .slide-overlay-wrapper {
    background-color: var(--c-mentor-green) !important;
  }



}