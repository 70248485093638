.credit-card-preview {
    background-color: var(--c-green);
    color: white;
    border: 1px solid #e3e3e3;
    border-radius: 6px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    padding: 8px 12px;
    position: relative;

    display: block;
    width: 90%;
    margin: auto;
    aspect-ratio: 1.586;
    margin-top: 20px;
  }
  
  .card-brand {
    height: 20px;
    position: absolute;
    top: 12px;
    left: 12px;
    width: 48px;
  }
  
  .card-brand img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  
  .card-number {
    font-family: 'Arial', sans-serif;
    font-size: 16px;
    font-weight: bold;
    position: absolute;
    top: 20px;
    left: 72px;
  }
  
  .card-last-four {
    font-family: 'Arial', sans-serif;
    font-size: 16px;
    position: absolute;
    top: 40px;
    right: 12px;
  }
  .credit-card-test-badge{
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: var(--c-red);
    border-radius: 4px;
    color: #ffffff;
    display: inline-block;
    padding: 5px 10px;
  }
  .credit-card-country{
    position: absolute;
    bottom: 10px;
    left: 10px;
    background-color: white;
    color: var(--c-green);
    border-radius: 4px;
    display: inline-block;
    padding: 5px 10px;
  }