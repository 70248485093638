

/* DEVTOOLS */

/* FONTS */
.t0{
    font-size: 72px;
}
.t1{
    font-size: 32px;
}
.t2{
    font-size: 20px;
}
.t3{
    font-size: 16px;
}
.t4{
    font-size: 12px;
}
.t5{
    font-size: 10px;
    line-height: 1
}

.megaemoji.font_size_24px{
    font-size: 24px;
}

.light{
    font-weight: 300;
}
.semibold{
    font-weight: 600;
}
.bold{
    font-weight: 700;
}
.line_height_normal{
    line-height: normal;
}
.line_height_1{
    line-height: 1;
}
.line_height_1_1{
    line-height: 1.1;
}
.line_height_1_4{
    line-height: 1.4;
}
.z90{
    z-index: 90;
}
.z99{
    z-index: 99;
}
.z100{
    z-index: 100;
}
.z101{
    z-index: 101;
}

/* OPACITY */
.opacity_0p{
    opacity: 0;
}
.opacity_10p{
    opacity: 0.1;
}
.opacity_20p{
    opacity: 0.2;
}
.opacity_30p{
    opacity: 0.3;
}
.opacity_40p{
    opacity: 0.4;
}
.opacity_50p{
    opacity: 0.5;
}

/* POSITIONS */
.float_right{
    float: right;
}
.position_relative{
    position: relative;
}
.position_absolute{
    position: absolute;
}
.bottom_5px{
    bottom: 5px;
}
.bottom_10px{
    bottom: 5px;
}
.right_10px{
    right: 10px;
}
.devtools{
    display: none;
}
/* CENTERING */
.center_vertically{
    display: inline-flex;
    align-items: center;
}


/* HOVER DROPDOWN */
.dropdown_hover:hover .dropdown_hover_content {
    display: block;
}
.dropdown_hover_content {
    z-index: 9999;
    right: 0;
    cursor: auto;
    color: #000;
    background-color: #fff;
    display: none;
    position: absolute;
    min-width: 160px;
    margin: 0;
    padding: 0;
    top: 30px;
}
.dropdown_hover_item {
    width: 100%;
    display: block;
    padding: 8px 16px;
    text-align: left;
    border: none;
    white-space: normal;
    float: none;
    outline: 0;
    color: var(--font-color);
    text-decoration: none;
    cursor: pointer;
    white-space: nowrap;
}
.dropdown_hover_item:hover{
    color: white;
    background-color: var(--font-color);
}

/* PADDING */
.padding_20px_horizontal{
    width: 100%;
    padding: 0 20px;
}
.padding_bottom_20px{
    padding-bottom:20px;
}
.padding_bottom_70px{
    padding-bottom: 70px;
}
.padding_top_80px{
    padding-top: 80px;
}
.padding_top_25px{
    padding-top:25px;
}
.padding_top_10px{
    padding-top: 10px;
}

/* TRANSFORM */
.transform_scale_3{
    transform: scale(3);
}
/* HEIGHT */

.height_16px{
    height: 16px;
}
.height_20px{
    height: 20px;
}
.height_30px{
    height: 30px;
}
.height_200px{
    height: 200px;
}
.height_400px{
    height: 400px;
}
.height_600px{
    height: 600px;
}
.height_700px{
    height: 700px;
}

.height_20p{
    height: 20%;
}
.height_40p{
    height: 40%;
}
.height_70p{
    height: 70%;
}
.height_70vh{
    height: 70vh;
}
.height_80p{
    height: 80%;
}
.height_100p{
    height: 100%;
}
/* WIDTH */

/* WIDTH PIXELS */
.width_8px{
    width: 8px;
}
.width_10px{
    width: 10px;
}
.width_45px{
    width: 45px;
}
.width_50px{
    width: 50px;
}
.width_75px{
    width: 75px;
}
.width_100px{
    width: 100px;
}
.width_120px{
    width: 120px;
}
.width_140px{
    width: 140px;
}


.width_150px{
    width: 150px;
}
.width_200px{
    width: 200px;
}
.width_250px{
    width: 250px;
}
.width_300px{
    width: 300px;
}
.width_400px{
    width: 400px;
}
.width_450px{
    width: 450px;
}
.width_600px{
    width: 600px;
}
.width_650px{
    width: 650px;
}
.width_800px{
    width: 800px;
}
.width_900px{
    width: 900px;
}
.width_1200px{
    width: 1200px;
}

/* WIDTH PERCETAGES */
.width_100p{
    width: 100% !important;
}
.width_95p{
    width: 95% !important;
}
.width_90p{
    width: 90%;
}
.width_80p{
    width: 80%;
}
.width_75p{
    width: 75%;
}
.width_70p{
    width: 70%;
}
.width_60p{
    width: 60%;
}
.width_50p{
    width: 50%;
}
.width_45p{
    width: 45%;
}
.width_40p{
    width: 40%;
}
.width_30p{
    width: 30%;
}
.width_20p{
    width: 20%;
}
.width_15p{
    width: 15%;
}
.width_10p{
    width: 10%;
}
.width_5p{
    width: 5%;
}
.width_2p{
    width: 2%;
}


/* WIDTH MIN MAX AUTO */

.width_auto{
    width: auto;
}
.max_width_none{
    max-width: none !important;
}
.max_width_50vh{
    max-width: 50vh;
}

/* DISPLAY */
.display_none{
    display: none;
}
.display_block{
    display: block;
}
.display_flex{
    display: flex;
}
.flex{
    display: flex;
}
.wrap{
    flex-flow: wrap;
}
.space_evenly{
    justify-content: space-evenly;
}
.display_inline_block{
    display: inline-block;
}
.margin_auto{
    margin: auto;
}
.text_align_center, .text_center{
    text-align: center;
}
.text_align_left{
    text-align: left;
}
.text_align_right{
    text-align: right;
}

.align_center_horizontally{
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
}
.align_center_vertically{
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
}
.vertical_align_top{
    vertical-align: top;
}

/* MARGINS */
.margin_left_0px{
    margin-left: 0px;
}
.margin_left_2px{
    margin-left: 2px;
}
.margin_left_4px{
    margin-left: 4px;
}
.margin_left_5px{
    margin-left: 5px;
}
.margin_left_10px{
    margin-left: 10px;
}
.margin_left_16px{
    margin-left: 16px;
}
.margin_right_20px{
    margin-right: 20px;
}

.margin_left_40px{
    margin-left: 40px;
}

/* MARGIN RIGHT */


.margin_right_5px{
    margin-right: 5px;
}

.margin_right_100px{
    margin-right: 100px;
}

.margin_left_auto{
    margin-left: auto;
}
/* MARGIN TOP */

.margin_top_2px{
    margin-top: 2px;
}

.margin_top_5px{
    margin-top: 5px;
}
.margin_top_13px{
    margin-top: 13px;
}
.margin_top_10px{
    margin-top: 10px;
}
.margin_top_20px{
    margin-top: 20px;
}
.margin_top_30px{
    margin-top: 30px;
}
.margin_top_40px{
    margin-top: 40px;
}
.margin_top_80px{
    margin-top: 80px;
}
.margin_top_160px{
    margin-top: 160px;
}


.margin_top_5p{
    margin-top: 5%;
}

.margin_top_10p{
    margin-top: 10%;
}


/* MARGIN BOTTOM */

.margin_bottom_5px{
    margin-bottom: 5px;
}
.margin_bottom_10px {
    margin-bottom: 10px;
}
.margin_bottom_20px {
    margin-bottom: 20px;
}
.margin_bottom_40px {
    margin-bottom: 40px;
}
.margin_bottom_80px {
    margin-bottom: 80px;
}

.margin_horizontal_20px {
    margin-left: 20px;
    margin-right: 20px;
}

.margin_horizontal_10px {
    margin-left: 10px;
    margin-right: 10px;
}

.margin_vertical_10px {
    margin-top: 10px;
    margin-bottom: 10px;
}

.margin_vertical_20px {
    margin-top: 20px;
    margin-bottom: 20px;
}

.margin_vertical_50px {
    margin-top: 50px;
    margin-bottom: 50px;
}

.overflow_auto{
    overflow: auto
}
.overflow_y_auto{
    overflow-y: auto;
}

.pointer {
    cursor: pointer;
}


.vertical_align_middle {
    vertical-align: middle;
}

.dflex {
    display: flex;
}

.justify_content_center {
    justify-content: center;
}

.justify_content_space_between {
    justify-content: space-between;
}

.justify_content_space_between {
    justify-content: space-between;
}

.align_items_center {
    align-items: center;
}

.flex_direction_column {
    flex-direction: column;
}

.flex_direction_row {
    flex-direction: row;
}
.flex_direction_row_reverse {
    flex-direction: row-reverse;
}
.flex_flow_wrap{
    flex-flow: wrap;
}
.grayscale{
    filter: grayscale(80%);
}
.grayscale_100p{
    filter: grayscale(100%);
}