.matched-modal-page {

  .modal-container{
    margin-top: -85px;
  }


  .matched-container {
    height: 100%;
    position: relative;
  }

  .matched-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to top, rgba(0, 0, 0, .8) 10%, rgba(40, 50, 60, 0.5) 100%);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 20%;
  }

  .matched-title {
    margin-bottom: 40px;
  }

  .matched-input {
    color: white;

    ion-icon {
      color: var(--matched-text-color);
      font-size: 20px;
    }

    .label {
      font-style: italic;
      text-align: center;
    }

    ion-toolbar {
      border-radius: 8px;
      overflow: hidden;

      ion-input {
        margin: 0 12px;
      }
    }
  }


  //MODAL
  .modal {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
  }

  .match-img {
    width: 100%;
    height: 45px;
    margin-bottom: 60px;
    object-fit: contain;
  }

  .random-avatar {
    position: relative;
    margin-bottom: 25px;
  }

  .avatar {
    position: absolute;
    left: 0;
    right: 0;
    top: -65px;
    border-width: 4px;
    border-style: solid;
  }

  .modal-content div {
    margin-bottom: 6px;
    font-size: 12px;
    color: black;
    text-align: center;
    font-weight: 700;
  }

  .distance-label {
    color: #C2C0C0;
  }

  .close-button {
    position: fixed;
    bottom: 30px;
    color: white;
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    cursor: pointer;
  }


  .status-online {
    position: relative;
    top: 5px;
    color: #0CBC8B;
    border-radius: 10px;
    border-width: 1px;
    border-style: solid;
    padding: 2px 15px;
    border-color: #e2e2e2;
    font-weight: 700;
  }

  .status-offline {
    position: relative;
    top: 5px;
    color: #C2C0C0;
    border-radius: 10px;
    border-width: 1px;
    border-style: solid;
    padding: 2px 15px;
    border-color: #e2e2e2;
    font-weight: 700;
  }

  .action-buttons {
    display: flex;
    margin-top: 20px;
    cursor: pointer;

    div {
      display: flex;
      width: 50%;
      height: 45px;
      justify-content: center;
      align-items: center;
      color: black;
      text-align: center;
      border-top-style: solid;
      border-top-width: 1px;
      border-color: #F0F0F0;
      font-size: 12px;
    }

    .chat-button {
      color: #FC566D;
      font-weight: 600;
      border-right-style: solid;
      border-right-width: 1px;
    }

    .profile-button {
      color: #333333;
      font-weight: 600;
      border-left-style: solid;
      border-left-width: 1px;
    }

  }


}