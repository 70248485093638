:root {

    --c-green: #a0d1bc;
    --c-yellow: #f7f2d4;
    --c-dark-yellow: #ffc83d;

    --c-white: white;
    --c-red: #fd4f68;
    --c-dark-gray: #333333;
    --c-light-gray: #9C9C9C;
    --c-superlight-gray: #DADADA;


    /* MENTOR */
    --c-mentor-green: #0CBC8B;


}

ion-app {
    background-color: var(--c-white);
    font-family: 'Poppins', sans-serif;
}

.background_gradient {
    background-image: linear-gradient(162.44deg, #9FD1BC 0%, #AFD19F 100%);
}

.green {
    color: var(--c-green);
}

.bgreen {
    background-color: var(--c-green);
}

.white {
    color: var(--c-white);
}

.red {
    color: var(--c-red);
}

.bred {
    background-color: var(--c-red);
}

.dark_yellow {
    color: var(--c-dark-yellow);
}

.light_gray {
    color: var(--c-light-gray);
}

.color_light_gray {
    color: var(--c-light-gray);
}

.dark_gray {
    color: var(--c-dark-gray);
}

.color_dark_gray {
    color: var(--c-dark-gray);
}

.pointer {
    cursor: pointer;
}

.ctiny_avatar {
    display: inline-block;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    overflow: hidden;
}

.ctiny_avatar>img {
    border-radius: var(--border-radius);
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
}

.ctextarea textarea {
    display: block;
    background-color: white;
    box-shadow: 1px 1px 2px 1px #888888;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px !important;
    border-radius: 15px !important;
    padding: 10px 10px !important;

}

.cinput {
    display: block;
    background-color: white;
    box-shadow: 1px 1px 2px 1px #888888;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    border-radius: 15px;
    padding: 5px 40px;
    border: 1px solid black !important;
}

.cborder_bottom {
    border-bottom: 1px solid rgb(0, 0, 0, 0.1);
}

.cdate_input {
    min-width: 150px;
    background-color: white;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    border-radius: 15px;
    padding: 5px 10px;
}

.cbutton {
    height: 80px;
}

.cbutton_red {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    background: var(--c-red);
    border-radius: 10px;
    color: #FFFFFF;
    padding: 15px 40px;
    cursor: pointer;
}

.cbutton_big {
    width: 90%;
    display: flex;
    flex-flow: column;
}

.cbutton_95p {
    width: 95%;
    margin: auto;
    padding: 20px 40px;
    font-size: 16px;
    cursor: pointer;
}

.cbutton_green {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    background: var(--c-green);
    border-radius: 10px;
    color: #FFFFFF;
    padding: 15px 40px;
    cursor: pointer;
}

.cbutton_gray {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    background: var(--c-dark-gray);
    border-radius: 10px;
    color: white;
    padding: 15px 40px;
    cursor: pointer;
}

.cbutton_disabled {
    background-color: var(--c-dark-gray);
    opacity: 0.2;
}

.megaemoji_OLD {
    width: 86px;
    height: 86px;
    font-size: 36px;
    border-radius: 50%;
    background-color: var(--c-green);
    padding: 15px 20px;
}

.megaemoji {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 86px;
    height: 86px;
    font-size: 36px;
    border-radius: 50%;
    background-color: var(--c-green);
}


.backround_almost_white {
    background-color: white;
}


/* SERVICES */
.cservice_title_image {
    border-radius: 25px;
    margin: auto;
    max-width: 95%;
    max-height: 250px;
    display: block;
}

/* HEARTS */
.big_heart {
    width: 100px;
}

.beating {
    animation: beating 1s ease infinite;
}

@keyframes beating {
    0% {
        transform: scale(.8);
        opacity: 1;
    }

    25% {
        transform: scale(1.1);
        opacity: 0.8;
    }

    75% {
        transform: scale(1);
        opacity: 0.9;
    }

    100% {
        transform: scale(0.8);
        opacity: 1;
    }

}

.spin {
    display: inline-block;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


.wizard_action_row {
    position: fixed;
    bottom: 30px;
    width: 100%;
    display: flex;
    flex-flow: column;
    left: 0;
    right: 0;
}

.big_action_button {
    position: fixed;
    bottom: 10px;
    width: 100%;
    display: flex;
    flex-flow: column;
}

.editable_div {
    background-color: #F9F9F9;
    border: 0.5px dashed #C3C3C3;
    border-radius: 10px;
    padding: 2px 10px;
}

.service_locked_icon {
    position: absolute;
    left: 50%;
    top: 30%;
    transform-origin: center center;
    transform: translate(-50%, -50%);
    font-size: 60px;
}



/* TUMBLEWEED */
.tumbleweed_animation {
    animation: tumbleweed 3s linear infinite;
}

@keyframes tumbleweed {
    0% {
        transform: rotate(0deg) translateY(0);
    }

    50% {
        transform: rotate(180deg) translateY(0px);
    }

    100% {
        transform: rotate(360deg) translateY(0);
    }
}

.switch_role_button {
    --box-shadow: 0 2px 6px 0 rgba(112, 125, 134, 0.14);
    --border-color: rgb(186, 186, 186);
    --border-radius: 999em;
    --ion-color-contrast: var(--c-white) !important;
    font-weight: 700;
    --padding-start: 60px;
    --padding-end: 60px;
}

.avatar_role_icon {
    position: absolute;
    bottom: 5px;
    right: 5px;
}