.order-card {
  position: relative;
  background: white;
  border-radius: 10px;
  border-style: solid;
  border-color: #F0F0F0;
  border-width: 1px;
  overflow: auto;
  cursor: pointer;


  //HEADER
  .card-header {
    display: flex;
    padding: 12px 16px;
    align-items: center;
    border-bottom-color: #F0F0F0;
    border-bottom-style: solid;
    border-bottom-width: 1px;

    ion-icon {
      color: #FC566D;
      font-size: x-large;
    }

    .card-title {
      color: #FC566D;
      font-weight: 600;
      font-size: small;
      margin-left: 10px;
    }

    .card-time {
      color: #999BA3;
      font-size: x-small;
      font-weight: 400;
      position: absolute;
      right: 15px;
    }

  }


  //MENTOR
  .mentor-container {
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 8px;

    .ctiny_avatar {
      width: 35px;
      height: 35px;
    }

    .mentor-info {
      margin-left: 8px;
      position: relative;
    }

    .mentor-name {
      font-weight: 600;
      font-size: small;
      color: #333333;
      align-items: center;
      display: flex;
      gap: 5px;

      ion-icon {
        color: #367CFF;
        font-size: large;
      }
    }

    .mentor-level {
      font-weight: 500;
      font-size: x-small;
      color: #848484;
      text-align: left;
    }

    .mentor-forward-icon {
      position: absolute;
      margin: auto;
      right: 0;
      top: 0;
      bottom: 0;
      font-size: larger;
      color: #D3D3D3;
    }

  }


  //SERVICE
  .service-image {
    width: 100%;
    object-fit: cover;
    padding: 10px 10px 0px 10px;
    border-radius: 20px;
  }

  .card-caption {
    width: 100%;
    margin: auto;
    padding-left: 16px;
    padding-right: 16px;
  }

  .caption-title {
    font-weight: 600;
    font-size: medium;
    color: black;
    margin-bottom: 4px;
    text-align: left;
  }

  .caption-bottom {
    display: flex;
  }

  .space {
    width: 100%;
  }

  .caption-price {
    font-weight: 700;
    font-size: medium;
    color: #FFB458;
  }

  .caption-time {
    position: absolute;
    top: 10px;
    left: 10px;
    width: max-content;
    background: white;
    border-radius: 20px;
    padding: 2px 15px;
    font-weight: bold;
    font-size: x-small;
  }

  .caption-actions {
    width: 100%;
    margin: auto;
    margin-top: 5px;
    border-top-color: #F0F0F0;
    border-top-style: solid;
    border-top-width: 1px;

    div {
      text-align: center;
      font-weight: 600;
      font-size: small;

      span {
        display: block;
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }

    .accept-button {
      border-right-color: #F0F0F0;
      border-right-style: solid;
      border-right-width: 1px;
      color: #0CBC8B;
    }

    .cancel-button {
      border-right-color: #F0F0F0;
      border-right-style: solid;
      border-right-width: 1px;
      color: #FC566D;
    }

    .caption-buttons {
      display: flex;
      gap: 5%;

      div {
        width: 100%;
      }

    }


    .status-buttons {
      display: flex;
      align-items: center;
      border-top-width: 1px;
      border-top-style: solid;
      border-top-color: #F0F0F0;

      .status-dot {
        width: 12px;
        height: 12px;
        margin-left: 15px;
        border-radius: 12px;
      }

      .status-text {
        margin-left: 15px;
        font-weight: 600;
        color: #92949C;
      }

      .status-button {
        position: absolute;
        right: 20px;
        color: #FC566D;
      }
    }

    .progress-status {
      .status-dot {
        background: #FFB458;
      }
    }

    .finished-status {
      .status-dot {
        background: #0CBC8B;
      }

      .status-text {
        color: #0CBC8B;
      }
    }


  }






}