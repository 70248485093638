.wizard-page {

    background: #f7f7f7 !important;

    ion-toolbar {
        height: 60px;
    }

    ion-row {
        --background: transparent;
    }

    ion-content {
        --background: transparent;
        background: #f7f7f7 !important;
        padding-bottom: 60px;
        --offset-top: 60px !important;
        --offset-bottom: 0px;
    }

    .top-emoji {
        display: flex;
        width: 60px;
        height: 60px;
        margin: auto;
        margin-top: -44px;
        font-size: 32px;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.01);
        background: white !important;
    }



    .top-title {
        color: var(--c-dark-gray);
        margin-top: 15px !important;
        text-align: center;
        margin: auto;
        font-size: 32px;
        font-weight: 500;
    }

    .top-description {
        width: 100%;
        margin: auto;
        margin-left: 20px;
        margin-right: 20px;
        text-align: center;
        font-size: 12px;
        line-height: 22px;
        color: var(--c-light-gray);
    }

    //CONTINUE BUTTON

    .cbutton_red {
        font-weight: 600;
        width: 85%;
        line-height: 30px;
        margin: auto;
        margin-top: 40px;
        cursor: pointer;
        background: var(--c-red);
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }


    .inputs-container {
        width: 90%;
        margin: auto;
        margin-top: 20px;
        padding-bottom: 100px;
    }

    .clabel {
        font-size: 12px;
        text-align: left;
        color: var(--c-red);
        position: absolute;
        left: 20px;
        top: 10px;
        font-weight: 600;
    }

    .cinput {
        background: white;
        border-radius: 10px;
        border: none !important;
        box-shadow: none;
        --width: 100%;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
        text-align: start;
        padding-left: 20px !important;
        padding-top: 20px !important;
        margin-bottom: 10px;
        font-weight: 600;
        color: var(--c-light-gray);
    }

    .red-label {
        color: var(--c-red);
    }



    //SELECT BUTTONS
    .select-button {
        position: relative;
        background: white;
        border-radius: 10px;
        width: 100%;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
        text-align: start;
        padding: 10px 20px 10px 20px;
        margin-bottom: 10px;
        border-style: solid;
        border-width: 1px;
        border-color: transparent;
        cursor: pointer;
    }

    .select-button .title {
        font-size: 12px;
        text-align: left;
        color: var(--c-red);
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .select-button .description {
        font-size: 12px;
        text-align: left;
        font-weight: 600;
        color: var(--c-light-gray);
        margin-top: 5px;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        margin-right: 20px;
    }

    .select-button ion-icon {
        position: absolute;
        color: var(--c-light-gray);
        right: 10px;
        display: block;
        bottom: 10px;
        font-size: larger;
    }

    .button-selected {
        border-color: var(--c-red);
    }



    //PHONE
    .PhoneInputInput {
        background: white;
    }


    .auth-code-input {
        background: white;
        border-radius: 10px;
        width: 30px;
        height: 40px;
        text-align: center;
        border-style: solid;
        border-width: 1px;
        border-color: white;
        margin-left: 5px;
        margin-right: 5px;
        background: white;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
    }


    //BIRTHDAY
    .cdate_input {
        min-width: 150px;
        border: none;
        outline: none;
        border-bottom: 1px solid #ccc;
        box-shadow: none;
        border-bottom: none;
        padding: 5px 10px;
        font-weight: 600;
        color: var(--c-light-gray);
    }


    //PHOTO
    .photo-grid {
        max-width: 94%;
    }

    .no-photo {

        ion-icon {
            color: var(--c-red) !important;
        }

        .photo-image {
            background: white !important;
            border-style: none !important;
        }

        .photo-button-frame {
            width: 100%;
            height: 100% !important;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        .photo-button {
            border-color: var(--c-red);
            border-width: 1px;
            border-style: solid;
            background: white !important;
        }

    }

    .has-photo {

        .photo-button-frame {
            justify-content: center;
        }

        .photo-button {
            color: var(--c-red) !important;
            border-width: 1px;
            border-style: solid;
        }
    }

    //INTERESTS

    .interests-list {
        background: transparent;
        padding-bottom: 60px;
    }

    .badges {

        ion-button {
            --background: white;
            --background-hover: white;
            --color-hover: var(--c-red);
            --border-color-hover: var(--c-red);
            --background-activated: white;
            --color-activated: var(--c-red);
        }

        .active {
            --border-color: var(--c-red);
            --border-style: solid;
            --border-width: 1px;
            --color: var(--c-red);
        }

        .inactive {
            --border-color: var(--c-light-gray);
            --border-style: solid;
            --border-width: 1px;
            --color: var(--c-light-gray);
        }

    }

    .select-button.height {
        padding-bottom: 0;
    }

    .height-input-container {
        display: flex;
        flex-direction: row;

        ion-select {
            font-size: 12px;
            font-weight: 600;
            color: var(--c-light-gray);
            margin-top: 5px;
            padding: 0;
            user-select: none;
            margin-right: -10px;
            text-align: right;
        }

        ion-item {
            height: 35px;
            --min-height: 42px;
        }

        .item-native {
            --padding-left: 0 !important;
        }

        h5 {
            font-size: 12px !important;
            font-weight: 600;
            color: var(--c-light-gray);
        }

    }

}


.setup-page {
    .top-emoji {
        margin-top: 44px;
    }
}

.phone-modal {

    #ion-react-wrapper {
        background: #f7f7f7 !important;
    }

    ion-header {
        background: transparent;
    }

    ion-content {
        --offset-top: 44px !important;
    }
}

.can-go-back {
    .top-emoji {
        margin-top: 0px !important;
    }
}

.alert-button-inner {
    color: #FC566D;
}