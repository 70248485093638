.scouting-invitation-page {

    .top-emoji {
        display: flex;
        width: 60px;
        height: 60px;
        margin: auto;
        margin-top: 12px;
        margin-bottom: 12px;
        font-size: 32px;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.01);
        background: white !important;
    }

    .list-custom {

        margin-left: 20px;
        margin-right: 20px;
        background: transparent;

        ion-item {
            margin-top: 12px;
            background: transparent;
            --background: white;
            --border-radius: 10px;
            --border-width: 1px;
            --border-style: solid;
            --border-color: #F0F0F0;
            cursor: pointer;
        }

        ion-note {
            font-size: smaller;
            font-weight: 600;
            color: #9C9C9C;
            padding: 15px 10px;
        }

        .red-text {
            color: #FC566D;
        }

        ion-icon {
            color: #92949C;
            font-size: large;
        }

        ion-input {
            font-size: smaller;
            font-weight: 600;
            text-align: start;
            color: #9C9C9C;

            input {
                padding-left: 12px;
            }
        }

        .red-icon {
            color: #FC566D;
        }

        .gray-circular-icon {
            background: #F3F3F3;
            color: #F3F3F3;
            border-radius: 50%;
            font-size: smaller;
            padding: 0px;
            margin-right: 12px;
            border-width: 1px;
            border-color: #D9D9D9;
            border-style: solid;
        }
    }


    .next-button {
        position: fixed;
        bottom: 30px;
        width: 100%;
        display: flex;
        flex-flow: column;
        left: 0;
        right: 0;


        div {
            display: flex;
        }

        p {
            width: 100%;
            margin-left: 20px;
            margin-right: 20px;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

    }
}